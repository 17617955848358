// 3rd party
import React, {useContext, useEffect} from "react"
import styled from "styled-components"

// constants / hooks
import {device, transition} from "../../constants/css"
import useCurrentWidth from "../hooks/useCurrentWidth"

// components
import NavLinkWrapper from "./navLinkWrapper"
import Bullet from "../icons/bullet"
import Link from "../general/link"

// context
import {NavStateContext, NavDispatchContext} from "../../context/NavContextProvider"
import {GlobalStateContext} from "../../context/GlobalContextProvider"

const NavMainItem = ({item, myRef}) => {
  // state
  const appState = useContext(GlobalStateContext)
  const navState = useContext(NavStateContext)
  const navDispatch = useContext(NavDispatchContext)

  // data
  const active = navState.main[appState.navType] === item.id
  const collapsed = appState.navIsCollapsed

  // hooks
  let width = useCurrentWidth()

  useEffect(() => {
    if (active) {
      navDispatch({type: "setNavMain", item: item})
    }
  }, [active, item, navDispatch])

  useEffect(() => {
    // make sure that on page load and after changes the correct nav sizes are set
    if (active) {
      let top = myRef?.current.getBoundingClientRect().top // get offset from top of screen
      navDispatch({type: "setTop", top: top})
    }
  }, [myRef, active, navDispatch, width])

  // functions
  const onHover = () => {
    if (!appState.navIsFrozen) {
      navDispatch({type: "setNavMain", item: item})
      navDispatch({type: "setNavSub", id: item.id})
    }
  }
  return (
    <Li ref={active ? myRef : null}>
      <NavLinkWrapper>
        {item.uri ? (
          <Link uri={item.uri}>
            <StyledLink
              onMouseOver={onHover}
              onFocus={onHover}
              selected={active}
              collapsed={collapsed}
              data-uri={item.uri}
              data-id={item.id}
            >
              {item.title}
            </StyledLink>
          </Link>
        ) : (
          item.title
        )}
        <Bullet />
      </NavLinkWrapper>
    </Li>
  )
}

const Li = styled.li`
  transition: all ${transition.nav}ms;
  padding-right: 15px;
  @media ${device.tabletM} {
    padding-right: 20px;
  }
  padding-bottom: 5px;
  position: relative;
`

const StyledLink = styled.span`
  cursor: pointer;
  overflow: hidden;
  text-transform: ${({selected}) => selected && "uppercase"};
`

export default NavMainItem
