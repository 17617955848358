// 3rd party
import React, {useContext, useEffect} from "react"
import styled from "styled-components"

// components
import Seo from "../components/general/seo"
import Container from "../components/styled/container"
import NavMain from "../components/nav/navMain" // for staticquery

// context
import {GlobalDispatchContext} from "../context/GlobalContextProvider"

const NotFoundPage = () => {
  // state
  const appDispatch = useContext(GlobalDispatchContext)

  // effect
  useEffect(() => {
    let pageTemplate = {medium: 0, project: 0}
    let page = {main: pageTemplate, sub: pageTemplate, currentId: 0, template: "", type: ""}

    appDispatch({type: "setPage", value: {type: "medium", page: page}})
    appDispatch({type: "setAttached", value: {items: [], currentId: 0}})
  }, [appDispatch])

  return (
    <>
      <Seo title="404" />
      <Container>
        <Message>
          <h1>NOT FOUND</h1>
          <p>This page doesn't exist.</p>
        </Message>
      </Container>
    </>
  )
}

const Message = styled.div`
  padding: 50px;
  margin: 50px auto 0 auto;
  text-align: center;
`

export default NotFoundPage
